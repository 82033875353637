// NOMENSA cards.scss
// REPLACE ./assets with /assets
.card-neat {
  background-color: $white;
  border-radius: 0;
  border-width: 0;
  box-shadow: 0 2px 13px 0 $shadow-08;
  width: 100%;

  @include respond-above(sm) {
    box-shadow: 0 2px 30px 0 $shadow-08;
  }
  .news-card-image {
    @include aspect-ratio(4, 3);
    width: 100%;
    object-fit: cover;
  }
  // Nomensa's image offset code, if we decide to use
  //.news-card-image {
  //  //margin-left: -1.45rem;
  //  //width: calc(100% - 0.5rem) !important;
  //}
  .event-card-image img {
    display: block;
    height: 180px;
    object-fit: cover;
    margin: auto;
  }

  .card-title {
    font-family: $secondary-font-semi-bold;
    font-size: 1.25rem;
  }

  .card-body {
    .card-text {
      > a {
        font-size: 1.125rem !important;
        font-weight: bold !important;
      }
      &.card-text01 {
        flex: 1 0 auto;
      }
      &.card-date-time {
        flex: 1 0 auto;
      }
    }
  }

  &.disabled {
    img {
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
    }
  }
}

.featured-news-item {
  max-width: 450px;
}

.sign-up-tabs {
  .tab-pane {
    .signtab-image {
      display: none;

      @include respond-above(md) {
        display: block;
        position: relative;
        z-index: 2;
      }

      img {
        max-width: 380px;
        width: 100%;
      }
    }

    .card {
      border-radius: 0;
      box-shadow: 0 2px 30px 0 $shadow-08;
      max-width: 380px;
      margin: 0 auto;
      position: relative;
      transform-style: preserve-3d;
      z-index: 1;

      @include respond-above(md) {
        &:before {
          @include pseudo-element;
          border: solid 3px #000000;
          height: 315px;
          left: -337px;
          top: -29px;
          transform: translateZ(-1px);
          width: 380px;
          z-index: 0;
        }

        &:after {
          @include pseudo-element;
          background-color: #000000;
          border-radius: 50%;
          left: -1px;
          height: 24px;
          top: 273px;
          width: 24px;
        }
      }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        &:before {
          display: none;
          @include respond-above(md) {
            @include pseudo-element;
            background-image: url('/assets/common/box-line.svg');
            background-repeat: no-repeat;
            border-width: 0;
            height: 318px;
            left: -438px;
            top: -31px;
            width: 500px;
          }
        }
      }
    }
  }
}

.simple-card {
  box-shadow: 0 2px 30px 0 $shadow-08;
  background-color: $white;
  clear: both;

  p:last-of-type {
    margin-bottom: 0;
  }
}
.basic-card {
  background-color: $white;
  box-shadow: 0 2px 30px 0 $shadow-08;
  height: 100%;

  h2 {
    font-size: 1.25rem;
  }
  > p {
    flex: 1 0 auto;
    margin: auto 0;
  }
  .list-group-item & > a {
    font-family: $primary-font;
  }
}
.action-card {
  height: 100%;
  h2 {
    font-family: $secondary-font-medium;
    font-size: 1.625rem;
  }
  p:first-of-type > span {
    display: inline-block;
    font-weight: 700;
  }

  p {
    flex: 1 0 3rem;
  }

  .btn {
    font-family: $secondary-font-bold;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.carousel {
  .news-card {
    max-width: 308px;
    @include respond-above(sm) {
      margin: 0.75rem;
    }
    &:first-of-type {
      margin-left: 1.75rem;
    }
    &:last-of-type {
      margin-right: 1.75rem;
    }

    a img {
      @include aspect-ratio(5, 3);
      object-fit: cover;
    }
  }
  .news-card-text p {
    max-height: 182px;
    overflow: hidden;
    // truncates longer news text with ... at the end. if not supported (IE + Opera Mini at time of writing), overflow still hidden.
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }
}
.news-card + a,
.events-carousel + a {
  display: block;
  font-family: $primary-font;
  font-size: 1.125rem;
  font-weight: bold;
  padding: 0;
}

.card-deck + .card-deck {
  margin-top: 1.5rem;
}

.card-deck {
  @extend .card-group;

  .news-card {
    flex: unset !important;
  }

  // de-couple the border
  @include media-breakpoint-up(sm){
      > .card {
          &:not(:first-child) {
              border-top-left-radius: $card-border-radius;
              border-bottom-left-radius: $card-border-radius;
          }

          &:not(:last-child) {
              border-top-right-radius: $card-border-radius;
              border-bottom-right-radius: $card-border-radius;
          }

          + .card {
              border-left: 1px solid $card-border-color;
          }
      }
  }

  // optionally change the breakpoint for column layout on mobile
  @include media-breakpoint-down(lg){
      flex-direction: column;
  }
}