@media print {

  @page {
    // min-width of 992px, scaled to 1:sqrt(2), plus 1in default padding on each side
    size: calc(992px + 1in) calc(992px * sqrt(2) + 1in);
  }

  body {
    min-width: unset !important;
  }

  header {
    box-shadow: none;
    nav {
      display: none !important;
    }
  }

  div#root {
    display: block !important;
    overflow: visible !important;
  }

  .question-content-container {
    display: block !important;
  }

  .no-print {
    display: none !important;
  }

  .no-shadow {
    box-shadow: none !important;
  }

  .force-print, .only-print {
    display: initial !important;
  }

  .cloze-drop-zone, .cloze-dropdown {
    border: grey solid 1px !important;
    background-color: transparent !important;
  }

  .cloze-item {
    color: black !important;
    border-color: black !important;
    background-color: unset !important;
  }

  .accordion {
    page-break-inside: avoid;
    box-shadow: none;
  }

  .content-body {
    background-color: white !important;
  }

  .collapse {
    display: initial !important;
  }

  .container {
    max-width: none;
    min-width: unset !important;
  }

  figure {
    box-shadow: none;
  }

  .question-panel {
    margin-left: 0 !important;
    max-width: 100%;
    flex-grow: 1;
  }

  .btn-secondary, .eqn-editor-preview, .form-control, .validation-response-panel {
    @extend .no-print
  }

  .tab-pane:not(.no-print) {
    @extend .force-print
  }

  .question-component {
    border-bottom: lightgray solid 1px;
    padding: 0 !important;
    margin-bottom: 1rem;
    box-shadow: none;
  }

  .expansion-layout {
    margin-left: 1px;
    width: 100%;
  }

  .parsons-available-items {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .content-value > div {
      display: block !important;
  }

  .difficulty-title {
    // force colour when printing hierarchy tags to ensure text is visible
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    &.active {
      // firefox printed SVG text is rendered via stroke, not color. this is NOT true when emulating media style, so we set both for consistency
      color: white !important;
      stroke: white !important;
    }
  }
}

.only-print {
  display: none;
}
