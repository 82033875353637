// Helper for playing with pseudo element
// $width: width of the pseudo element.
// $height: height of the pseudo element.
// $content: when playing with pseudo element, you mainly use `content: ""` (default value).
// $position: when playing with pseudo element, you mainly use `position: absolute` (default value).
// $display: when playing with pseudo element, you mainly use `display: block` (default value).
@use "sass:math";

@mixin pseudo-element($content: '', $position: absolute, $display: block) {
  content: $content;
  display: $display;
  position: $position;
}
// Detect IE10 and IE11
@mixin ie() {
  @media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
    @content;
  }
}
//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––
// A map of breakpoints.
// /!\ WARNING /!\ this is not the same as bootstraps $grid-breakpoints! (although it really should be!!!)
$breakpoints: (
        xs: 576px,
        sm: 768px,
        md: 992px,
        lg: 1200px,
        nav: 1256px
); //
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}
//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––
// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
// outline helper
@mixin out($colour: pink, $width: 1px) {
  outline: $width solid $colour;
}
@mixin placeholder($colour: pink) {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  &::placeholder {
    color: $colour !important;
    /* Firefox */
    opacity: 1;
  }
  /* Some older WebKit based browsers */
  &::-webkit-input-placeholder {
    color: $colour !important;
    opacity: 1;
  }
  /* Internet Explorer 10-11 */
  &:-ms-input-placeholder {
    color: $colour !important;
  }
  /* Microsoft Edge */
  &::-ms-input-placeholder {
    color: $colour !important;
  }
}

$viewport-sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto,
);

// Size / viewports -- (correctly) uses Bootstrap's breakpoints, NOT the ones declared in this file
@each $bp, $bp-width in map-remove($grid-breakpoints, xs) {
  @media (min-width: $bp-width) {
    @each $size, $size-value in $viewport-sizes {
      .w-#{$bp}-#{$size} {
        width: #{$size-value} !important;
      }
      .h-#{$bp}-#{$size} {
        height: #{$size-value} !important;
      }
    }
  }
}

// Fixed sizes
@for $size from 1 through 20 {
  .wf-#{$size} {
    width: #{$size}rem !important;
  }
  .hf-#{$size} {
    height: #{$size}rem !important;
  }
}

.mw-auto {
  min-width: auto !important;
}

@mixin aspect-ratio($width, $height) {
  aspect-ratio: calc($width / $height);
}

@mixin svg-icon($path, $width: 16px, $height: 16px, $mask-size: none, $mask-position: none) {
  display: inline-block;
  mask: url($path) center center no-repeat;
  width: $width;
  height: $height;
  min-width: $width;
  min-height: $height;
  background-color: black;
  @if $mask-size != none {
    mask-size: $mask-size;
  }
  @if $mask-position != none {
    mask-position: $mask-position;
  }
}

@mixin svg-icon-layered($fg_path, $bg_path, $width: 16px, $height: 16px, $mask-size: none, $mask-position: none) {
  display: inline-block;
  width: $width;
  height: $height;
  min-width: $width;
  min-height: $height;
  &:is(i.icon.icon) { // increase specificity over theming
    background-color: transparent;
  }
  &::before {
    content: '';
    @include svg-icon($bg_path, $width, $height);
    position: absolute;
    @if $mask-size != none {
      mask-size: $mask-size;
    }
    @if $mask-position != none {
      mask-position: $mask-position;
    }
  }
  &::after {
    content: '';
    @include svg-icon($fg_path, $width, $height);
    position: absolute;
    @if $mask-size {
      mask-size: $mask-size;
    }
    @if $mask-position != none {
      mask-position: $mask-position;
    }
  }
}

@mixin svg-color($image, $color, $width, $height, $mask-size: none) {
  mask: $image no-repeat center center;
  background-color: $color;
  width: $width;
  height: $height;
  @if $mask-size != none {
    mask-size: $mask-size;
  }
}
