.bg-grey {
  background: rgba($gray-120, 0.6);
}

.icon-email-status {
  display: inline-block;
  width: 25px;
  height: 19px;
  margin-left: 15px;
  margin-bottom: -7px;
  background: url(/assets/common/email-status.png) no-repeat;
  background-size: 75px;
}
.icon-email-status.unverified {
  background-position: -25px 0px;
}
.icon-email-status.failed {
  background-position: -50px 0px;
}

.icon-reset {
  display: inline-block;
  overflow: hidden;
  width: 1.25rem;
  height: 1.25rem;
  background: url(/assets/common/icons/reset.svg) no-repeat;
  margin-bottom: -4px;
  margin-left: 10px;
  margin-right: 10px;
}

polygon.fill-secondary {
  fill: $secondary;
}

.dropzone-dropdown {
  position: absolute;
  align-self: center;
  right: 5px;
  @extend .icon-dropdown-180;
  transition: transform 0.15s ease-in-out;
  opacity: 0.3;
}

@mixin icon-dropdown($deg) {
  transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -ms-transform: rotate($deg);
}

.icon-dropdown-180 {
  &.active {
    @include icon-dropdown(180deg);
  }
}

.icon-dropdown-90 {
  &.active {
    @include icon-dropdown(90deg);
  }
}

img[aria-disabled="true"] {
  opacity: 0.5;
}
