@import "../common/finder";

@media (min-width: 992px) {
  .finder-panel::before {
    position: absolute;
    content: "";
    width: 500px;
    height: 500px;
    top: -162px;
    left: 61%;
    background-size: cover;
    background-image: url("/assets/cs/decor/dots-circle-bg.svg");
  }
}
