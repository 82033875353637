@import "../common/login";

.google-button-logo {
  padding-right: 1.5rem;
  transform: none !important;
}

.rpf-button-logo {
  width: 1.5rem;
  margin-right: 1.5rem;
}