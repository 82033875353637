@import "../common/quiz.scss";

.group-counter {
    text-align: center;
    background-color: $primary !important;
    border-radius: 5px 0 0 5px;
    
    span {
        color: $white;
    }
}

.set-quiz-table tr:not(:first-child) td {
    border-top: 1px solid $cs-dark-silver;
}

.set-quiz-table-inner thead tr th .sort {
    height: 0;
    margin-top: -6px;
    .down {
        margin-top: -10px;
    }
}
