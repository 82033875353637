// ISAAC

@import "mixins";

.banner {
  background: rgba($gray-blue, 0.1);
  box-shadow: 0 2px 30px 0 $shadow-08;
  z-index: 1;
  border-top: solid 1px rgba($gray-blue, 0.6);
  border-bottom: solid 1px rgba($gray-blue, 0.6);
}
.banner-button {
  @include respond-below(sm) {
    width: 100%;
  }
  @include respond-below(lg) {
    min-width: 150px;
  }
  //border-color: $purple !important;
  //color: $purple !important;
}

.toasts-container {
  position: fixed;
  @include respond-below(sm) {
    top: 20px;
    right: 20px;
  }
  @include respond-above(sm) {
    top: 98px;
    right: 98px;
  }
  z-index: 10000;

  .fade {
    transition: opacity 0.4s linear, max-height 0.3s ease-out, margin-bottom 0.3s linear, border-width 0.3s linear;
  }
  .toast:not(.show) {
    max-height: 0;
    margin-bottom: 0;
    border: none;
  }
}

.toast {
  max-width: unset;
  background-color: rgba(255, 255, 255, 0.92);
}
.toast-header {
  font-size: 1.6rem;
  > svg {
    min-width: 20px;
  }
}
.toast-heading {
  padding-right: 1rem;
}
.toast-body {
  font-size: 1rem;
  max-width: min(calc(100vw - 40px), max(50vw, 400px));
}

header {
  box-shadow: 0 2px 30px 0 $shadow-08;
}

.title-help {
  display: flex;
  align-items: center;
  cursor: help;
  font-size: 1.25rem !important;
  line-height: 1.2 !important;
  min-height: 50px !important;
}

.title-help-modal {
  cursor: pointer !important;
}

.h-subtitle {
  padding-top: 5px;
  margin-bottom: -5px;
  font-size: 1.25rem;
  line-height: 1.2;
}

a.skip-main {
  left:-999px;
  position:absolute;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  z-index:-999;
}
a.skip-main:focus, a.skip-main:active {
  color: #000;
  background-color:#fff;
  left: 5%;
  top: 60px;
  width: 175px;
  height: auto;
  overflow: auto;
  margin: 10px;
  text-align:center;
  font-family: $secondary-font-medium;
  font-size: 1rem;
  z-index:999;
}
