@import "../common/icons";

.has-tip {
  cursor: help;
}
.icon-help {
  display: inline-block;
  cursor: help;
  overflow: hidden;
  width: 1.25rem;
  height: 1.25rem;
  background: url(/assets/common/icons/info.svg) no-repeat;
  margin-bottom: -4px;
  margin-left: 10px;
  margin-right: 10px;
}

.icon-help-q {
  display: inline-block;
  cursor: help;
  overflow: hidden;
  width: 1.5rem;
  height: 1.5rem;
  background: url(/assets/cs/icons/help.svg) no-repeat;
  margin-right: 10px;
}
.icon-error {
  display: inline-block;
  width: 24px;
  min-width: 24px;
  height: 24px;
  background: url(/assets/cs/icons/error.svg) no-repeat;
  margin-bottom: -6px;
}
.icon-person-active {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background: url(/assets/cs/icons/person-pink.svg) no-repeat;
  margin-bottom: -4px;
  margin-left: 10px;
  margin-right: 10px;
}
.icon-person-white {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background: url(/assets/cs/icons/person-white.svg) no-repeat;
  margin-bottom: -4px;
  margin-left: 10px;
  margin-right: 10px;
}
.icon-person {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background: url(/assets/cs/icons/person.svg) no-repeat;
  margin-bottom: -4px;
  margin-left: 10px;
  margin-right: 10px;
}
.icon-group {
  display: inline-block;
  overflow: hidden;
  width: 1.5rem;
  height: 1.25rem;
  background: url(/assets/cs/icons/group.svg) no-repeat;
  margin-left: 10px;
  margin-right: 10px;
}
.icon-group-white {
  display: inline-block;
  overflow: hidden;
  width: 1.5rem;
  height: 1.25rem;
  background: url(/assets/cs/icons/group-white.svg) no-repeat;
  margin-left: 10px;
  margin-right: 10px;
}
.icon-group-table-person {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background: url(/assets/cs/icons/person.svg) no-repeat;
  margin-bottom: -4px;
  margin-right: 10px;
}
.icon-ai {
  display: inline-block;
  background: url('/assets/cs/icons/ai.svg') no-repeat;
  width: 24px;
  height: 24px;
}
.icon-lock {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(/assets/cs/icons/lock.svg) no-repeat;
  margin-bottom: -4px;
}
.icon-feedback-tick {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(/assets/cs/icons/tick-rp-green.svg) no-repeat;
  margin-bottom: -5px;
}
.icon-feedback-sent-tick {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(/assets/cs/icons/feedback-sent-tick.svg) no-repeat;
  margin-bottom: -7px;  
}
icon-status {
  width: 23px;
  margin: -1px;
}

// This class is used in a number of non-icon-related places on both sites
.bg-grey {
  background: $cs-silver;
}
