// ISAAC temp hopefully
small {
  border: 0 !important;
}

.text-muted {
  a {
    color: $text-muted !important;
  }
}

ul {
  &.no-bullet {
    list-style-type: none;
    list-style-image: none;
  }
}

// NOMENSA typography.scss
// REPLACE ./assets WITH /assets

div.isaac-nav-link {
  color: $gray-194;
  display: inline-block;
  text-decoration: underline;

  &:hover {
    color: $gray-194;
    text-decoration: none;
  }

  &.a-alt {
    color: $purple;
  }

  &.lrg-text {
    font-size: 1.125rem;
  }

  &.x-lrg-text {
    font-size: 1.25rem;

    @include respond-above(sm) {
      font-size: 1.625rem;
    }
  }

  &.next-link {
    padding-right: 1.2rem;
    background-image: url('/assets/common/icons/chevron_right.svg');
    background-repeat: no-repeat;
    background-position: center right;
    clear: right;
    text-decoration: none;
  }
  &.previous-link {
    padding-left: 1.2rem;
    background-image: url('/assets/common/icons/chevron_left.svg');
    background-repeat: no-repeat;
    background-position: center left;
    clear: left;
    text-decoration: none;
  }
}

.user-select-all {
  user-select: all;
}

.text-decoration-skip-none {
  text-decoration-skip: none;
  text-decoration-skip-ink: none;
}
