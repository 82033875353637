#homepage {
  //section {padding: 2rem 0;}

  .bg-hero {
    position: absolute;
    top: 0;
    left: 0;
    filter: opacity(70%) blur(1px);
  }

  h1#homepageName {
    text-shadow: 0 0 4px $gray-103, 0 0 5px $gray-103, 0 0 10px $gray-103, 0 0 10px $gray-103, 0 0 20px $gray-103;
  }

  section#call-to-action {
    background-color: $cs-black;
    color: $cs-white;
  }

  img#homepageHeroImg {
    left: 50%;
    transform: translateX(-50%);
    bottom: -15%;
    z-index: -1;
    position: absolute;
    @include media-breakpoint-only(sm) {
      margin-bottom: -50%;
    }
    opacity: 0.6;
  }

  section#scotland-slice {
    background-color: $cs-white;
    #scotland-slice-card {
      background-color: $cs-cultured;
      border-radius: 24px;
      &:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-image: url(/assets/cs/decor/fingerprint-stage-slice.svg);
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 50%;
        background-size: 50%;
        filter: opacity(7%);
        @include media-breakpoint-down(lg) {
          background-size: 90%;
          background-position-x: 300%;
        }
      }
    }
  }

  section#benefits-for-teachers-and-students {
    ul {
      padding-inline-start: 20px;
      li {
        margin: 1rem 0;
      }
      li::marker {
        color: $primary;
      }
    }
  }

  section#what-resources {
    background-color: $cs-cultured;
    ul {
      padding-inline-start: 20px;
    }
  }

  section#computer-science-stories {
    .container {
      position: relative;
      .row {
        @include media-breakpoint-down(md) {
          padding-bottom: 40vw !important;
        }
        #cs-stories-text {
          background: radial-gradient(rgba(255, 255, 255, .9) 0, rgba(255, 255, 255, .8) 60%, transparent 80%, transparent 100%);
          z-index: 1;
        }
      }
      &:before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // Hand-tuned background positioning to make this particular SVG look nice on different screen sizes
        background-image: url(/assets/cs/decor/fingerprint-cs-stories.svg);
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 40%;
        background-size: auto 120%;
        @include media-breakpoint-down(lg) {
          left: 11vw;
        }
        @include media-breakpoint-down(md) {
          left: 0;
          width: 100%;
          background-position-x: 100%;
          background-position-y: 150%;
          background-size: 100% 80%;
        }
      }
    }
    background-color: $cs-white;
    position: relative;
  }

  section#news {
    background-color: $cs-cultured;

    @include media-breakpoint-only(lg) {
      .card-deck .news-card:nth-child(4) {
        display: none !important;
      }
    }
  }

  section#search {
    background-color: $dark-pink-300;
  }
}

.homepage-padding {
  @extend .px-md-4;
  @include media-breakpoint-up(lg) {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  @extend .py-5;
  @include media-breakpoint-up(lg) {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

}

.ada-pink {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: conic-gradient(from 180deg at 50% 50%, #FF4DC9FF 0deg, rgba(0, 0, 0, 0) 273.75deg, rgba(0, 0, 0, 0) 360deg);
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.ada-blue {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: conic-gradient(from 180deg at 50% 50%, #0AFFE7FF 0deg, rgba(10, 255, 231, 0) 273.75deg, rgba(10, 255, 231, 0) 360deg);
  transform: rotate(-180deg);
}
