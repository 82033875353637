.styled-toggle {
    $toggle-span-min-width: 4rem;
    $toggle-span-normal-x-padding: 15px;
    $toggle-span-reduced-x-padding: 8px;

    line-height: 18px;
    align-items: center;
    margin-bottom: 0;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    border-radius: 3rem;
    padding: 1px;
    background: darken(white, 15%);

    > input {
        appearance: none;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        margin: 0px;
        padding: 0px;
        border: none;
        background: transparent;
        cursor: pointer;
        border-radius: 3rem;

        &:checked ~ span:last-of-type, &:not(:checked) ~ span:first-of-type {
            background: white;
            box-shadow: $shadow-25 0px 0px 2px;
            color: black;
            padding: 7px $toggle-span-normal-x-padding;
        }

        &:checked ~ span:first-of-type, &:not(:checked) ~ span:last-of-type {
            min-width: calc($toggle-span-min-width + $toggle-span-reduced-x-padding - $toggle-span-normal-x-padding);
        }
    }

    > span {
        text-align: center;
        font-size: 12px;
        min-width: $toggle-span-min-width;
        font-family: $secondary-font-semi-bold;
        line-height: 1;
        cursor: pointer;
        display: inline-block;
        padding: 7px $toggle-span-normal-x-padding;
        transition: all 100ms ease-out;
        user-select: none;
        border-radius: 3rem;
        background: transparent;
        color: lighten(black, 40%);
    }

    > span:first-of-type {
        padding-right: $toggle-span-reduced-x-padding;
    }

    > span:last-of-type {
        padding-left: $toggle-span-reduced-x-padding;
    }
}
