// ISAAC

$cloze-dropdown-height: 300px;
$cloze-scrollbar-height: 4px;

.question-panel, .question-panel > .examboard-special-tabs {
  > .content-chunk > .content-value {
    background-color: white;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
    margin: auto auto 2rem auto;
    padding: $question-padding;
    @include media-breakpoint-down(md) {
      padding: 1rem;
    }

    font-family: $secondary-font;
    font-size: $question-font-size; // 1.25rem;
    line-height: $question-line-height; // 1.6rem;
    font-weight: $question-font-weight;
  }
}

.unit-dropdown button {
  min-width: auto;
}

.question-component {
  margin-bottom: 2rem;
  clear: both;

  .question-content {
    font-family: $secondary-font;
    font-size:  $question-font-size;
    font-weight: 400;
    line-height: $question-line-height;
  }

  .custom-control-label {
    &:before, &:after {
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -0.25rem;
    }
  }
}

.numeric-question {
  .numeric-help {
    min-width: 4rem !important;
  }

  .unit-selection {
    .dropdown-menu {
      width: 100%;
      min-width: max-content;
    }
    button.btn {
      padding: 0.25rem 1.5rem;
      min-width: 192px;
      width: 100%;
      &.selected {
        border-radius: 0;
      }
      &.dropdown-toggle::after {
        position: absolute;
        right: 0.5rem;
        top: 1rem;
      }
    }
  }
}

$inline-feedback-size: 20px;
$inline-feedback-padding: 2px;

.inline-outer-container {
  display: inline-flex;
  justify-content: center;
  width: max-content;
  vertical-align: middle;

  &:has(.inline-data-target.inline-stretch) {
    width: 100%;
  }
}

.inline-data-target.inline-data-target {
  min-width: calc($inline-feedback-size + 2 * $inline-feedback-padding + 9px + 1em); // feedback + padding + 9px extra (left) padding + 1em of input space
  min-height: $inline-feedback-size;

  // These are intended to be overridden as needed by the content -- classes can be specified in the markdown via [inline-question:id class="..."]
  width: 100px;
  height: 27px;

  &.inline-stretch {
    width: 100%;
  }
}

.inline-container {
  display: inline-flex;
  border-radius: var(--bs-border-radius);
  width: 100%;
  
  .feedback-wrapper {
    // wraps around a SINGLE component that can be marked as valid/invalid/unanswered, to allow multiple parts of one question to have different feedback states
    // requires that correctnessClass(...) is applied to the child, as to colour the component
    display: block;
    position: relative;

    > .is-valid, > .is-unanswered, > .is-invalid {
      padding-right: calc($inline-feedback-size + 2 * $inline-feedback-padding - 2px) !important; // -2px to account for border
    }

    > input {
      background: none; // remove default bootstrap background from is-valid, is-invalid
      padding: 0 10px;
    }

    &::after{
      top: calc((100% - $inline-feedback-size) / 2);
      right: $inline-feedback-padding;
      width: $inline-feedback-size;
      height: $inline-feedback-size;
      text-align: center;
      align-content: center;
      line-height: 100%;
      background-color: white;
      border-radius: 5px;
      pointer-events: none;
    }

    &:has(> .is-valid)::after {
      @include pseudo-element("✔");
      color: map-get($theme-colors, "success");
    }
    &:has(> .is-invalid)::after {
      @include pseudo-element("✘");
      color: map-get($theme-colors, "failed");
    }
    &:has(> .is-unanswered)::after {
      @include pseudo-element("!");
      color: map-get($theme-colors, "in-progress");
      font-weight: bold;
    }
  }

  input.is-valid, button.is-valid {
    border: solid 1px map-get($theme-colors, "success");
    @include border-radius($input-border-radius, 0);
  }

  input.is-invalid, button.is-invalid {
    border: solid 1px map-get($theme-colors, "failed");
    @include border-radius($input-border-radius, 0);
  }

  input.is-unanswered, button.is-unanswered {
    border: solid 1px map-get($theme-colors, "in-progress");
    @include border-radius($input-border-radius, 0);
  }

  $shadow-size: 2px;
  &.selected-feedback {
    box-shadow: 0 0 0 $shadow-size #000;
    &.is-valid {
      box-shadow: 0 0 0 $shadow-size map-get($theme-colors, "success");
    }
    &.is-invalid {
      box-shadow: 0 0 0 $shadow-size map-get($theme-colors, "failed");
    }
    &.is-unanswered {
      box-shadow: 0 0 0 $shadow-size map-get($theme-colors, "in-progress");
    }
  }
}

.inline-string-container {
  @extend .inline-container;
}

.inline-numeric-container {
  @extend .inline-container;

  @media screen {
    .units-shown {
      border-radius: 5px 0 0 5px !important;
    }
  }

  > .inline-unit-dropdown {
    > button.btn {
      display: block;
      height: 100%;
      min-width: 36px !important;
      border-radius: 0 5px 5px 0;
      border: none;
      &:active {
        border: none !important;
      }
      &.feedback-shown {
        padding-right: 36px !important;
      }

      > div {
        // force contents of button to fit in $inline-feedback-size, so we can use it at all sizes
        height: $inline-feedback-size;
        margin-top: -9.5px; 
      }
    }
    &.display-unit {
      button.btn {
        border: 1px solid black;
      }
    }
  }
}

.inline-multi-choice-container {
  @extend .inline-container;

  
  button.btn {
    min-width: unset;
    font-family: $primary-font !important;
    background-color: transparent;
    border-radius: 0.35rem;

    &:not(.is-valid):not(.is-invalid):not(.is-unanswered) {
      border: $gray-136 solid 1px;
    }

    > div {
      margin-top: -1px; // equal to the border width above, re-centres contents
    }
  }
}

.symbolic-question, .equality-page {
  .eqn-editor-preview {
    padding: 0.15em 0.6em;
    cursor: default;
    &.empty {
      color: $gray-500;
    }
  }

  .equality-page {
    .eqn-editor-preview {
      background: $white !important;
    }
  }

  .eqn-editor-input input {
    font-family: monospace;
    height: auto;
  }

  .eqn-editor-help {
    min-width: 0;
  }

  .eqn-editor-symbols {
    margin: 10px 0;

    & pre {
      display: inline;
      white-space: pre-wrap;
      background: inherit;
      border: 0;
      padding: 0;
    }
  }
}

#inequality-help {
  min-width: 0;
}

.parsons-question {
  .parsons-items {
    border: solid 1px #00000021;
    padding: 0 0.5em;

    &.empty {
      padding: 0.875em 0.5em;
      &.drag-over {
        padding-top: 1.1em;
        padding-bottom: 1.1em;
      }
    }

    .parsons-item > pre {
      margin: 0.5rem 0;
      padding: 0.5em 1em;
      cursor: grab; // Doesn't work?
    }

    .reorder-item {
      > * {
        overflow-x: auto;
        margin: 0.5rem 0;
        padding: 0.5em 1em;
        cursor: grab;
        background: white;
        border: solid 1px #00000021;
      }
    }

    // REMINDER: If you change the PARSONS_MAX_INDENT and PARSONS_INDENT_STEP
    // constants, you also have to change these two in here.
    $parsons-max-indent: 3;
    $parsons-step: 45;

    .parsons-item {
      @for $i from 0 through $parsons-max-indent {
        &.indent-#{$i} {
          margin-left: #{$i * $parsons-step}px;
        }
      }

      position: relative;

      .controls {
        display: none;
      }

      .show-controls {
        .controls {
          position: absolute;
          right: 0.5rem;
          top: 0.5rem;
          display: block;

          span {
            display: inline-block;
            width: 20px;
            height: 20px;
            cursor: pointer;

            &.show:hover {
              background-color: $primary !important;
            }

            &.hide {
              opacity: 0.2;
              cursor: default;
            }

            &.show {
              opacity: 1.0;
            }

            &.reduce {
              background: $gray-120 url('/assets/common/icons/chevron_left.svg') no-repeat center;
              margin-right: 4px;
            }

            &.increase {
              background: $gray-120 url('/assets/common/icons/chevron_right.svg') no-repeat center;
            }
          }
        }
      }
    }

    &#parsons-choice-area {
      @for $i from 0 through $parsons-max-indent {
        &.ghost-indent-#{$i} {
          background: linear-gradient(to right,
            white #{$i * $parsons-step + 5}px, $gray-400 #{$i * $parsons-step + 5}px, $gray-400 #{$i * $parsons-step + 7}px, white #{$i * $parsons-step + 7}px
          );
        }
      }

      &.is-dragging {
        .parsons-item {
          opacity: 0.666;
        }
      }
    }
  }
}

.inequality-results {
  pre {
    white-space: pre-wrap;
  }
}

.quick-question {
  button {
      box-shadow: none !important; // How horrible...
  }
  .alert {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.validation-response-panel {
  text-align: center;
  border-top: solid 1px $gray-107;
  border-bottom: solid 1px $gray-107;
  &.correct, &.almost {
    background: $primary;
    color: black;
    margin-left: -3rem;
    margin-right: -3rem;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    @include media-breakpoint-down(md) {
      margin-left: -1rem;
      margin-right: -1rem;
      margin-bottom: -1rem;
    }
  }
  &.correct {
    margin-bottom: -3rem;
  }
  p {
    margin-bottom: 0;
  }
}

// NOMENSA question.scss
.question-component {
  background-color: white;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem;
}

.cloze-question {
  // This is a work around, allowing KaTeX to be used for cloze questions.
  //  There is no guarantee that it works all of the time, so needs to be
  //  checked on a case by case basis.
  .katex {
    display: unset;
    * {
      pointer-events: none;
    }
    .cloze-item, .cloze-dropdown * {
      pointer-events: all;
    }
  }
  .cloze-item, .cloze-dropdown * {
    pointer-events: all;
    .katex-display {
      display: unset;
    }
  }
  .dropdown-menu {
    max-height: $cloze-dropdown-height;
    overflow-y: scroll;
    .invalid{
      color: darkgray;
    }
  }
}

.item-section {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: center;
  // gridAutoRows: "max-content",
  // gridTemplateColumns: "repeat(2, 1fr)",
  min-height: 64px;
}

.cloze-item {
  touch-action: none;
  position: relative;
  font-size: 1rem !important;
  border: 1px solid $border-color;

  &.is-dragging {
    opacity: 0.666;
  }
}

.dropdown .cloze-item {
  border: none;
}

.cloze-drop-zone, .cloze-dropdown {
  &.incorrect {
    filter: drop-shadow(0 0 0.3rem red);
  }
}

.cloze-dropdown > button {
  min-width: unset !important; // Bootstrap thinks its important
  &.empty.empty {
    &, &:hover, &:active, &:focus { // Apply regardless of hover
      border: grey solid 1px !important;
      background-color: transparent !important;
    }
  }
}

// Double class to give this more specificity
.katex.katex {
  font-size: 1.1em;
  white-space: nowrap !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  display: inline;
}

// cloze dropdowns inside katex are normally cut off due to content-driven overflows we cannot control easily. this:
//    a) enforces that dropdowns are provided $cloze-dropdown-height of space (maximum height before scrolling);
//    b) sets the correct overflow behaviour for the parent of the katex containing the dropdown;
//    c) if webkit exists, recreates the scrollbar to forcibly display it on OSX.

p > .katex:has(.cloze-dropdown.show), .katex-display:has(.cloze-dropdown.show) {
  padding-bottom: calc(0.25rem + $cloze-dropdown-height) !important;
  margin-bottom: -$cloze-dropdown-height !important;
  overflow-x: hidden !important;
}

p > .katex, .katex-display { 
  overflow: auto hidden;

  .katex .vlist-t2 {
    margin-right: 0 !important;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: $cloze-scrollbar-height;
  }
    
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .3);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .dropdown-menu {
    position: absolute;
  }
}

.feedback-zone {
  position: relative;
  &.feedback-showing {
    padding-right: 34px !important;
  }
  .feedback-box {
    text-align: center;
    position: absolute;
    right: 3px;
    top: 3px;
    color: black;
    border-radius: 2.5px; //
    height: calc(100% - 6px);
    padding-left: 6px;
    padding-right: 6px;
    background-color: white;
    .feedback {
      position: relative;
      display: inline-block;
      top: 50%;
      transform: translateY(-50%);
      &.correct {
        color: green;
      }
      &.incorrect {
        color: red;
      }
      &.unanswered {
        color: orange;
      }
    }
  }
  &.nq-feedback {
    input {
      background-image: none !important;
    }
    .feedback-box {
      z-index: 3;
      right: 75px;
    }
  }
}

.question-actions {
  align-self: flex-start;
  &.question-actions-leftmost {
    margin-left: auto;
  }
  .question-actions-icon {
    float: right;
  }
}

// Could be used to display draggables when using drop zones in text or code (if we got rid of the purple blocks)
//.cloze-item & .minimal {
//  text-decoration: underline dashed $secondary;
//}

// Could be used to outline draggable pieces of text
//.cloze-item & .minimal:after {
//  position: absolute;
//  left: 50%;
//  top: 50%;
//  transform: translate(-50%, -50%);
//  height: 120%;
//  display: block;
//  width: max(120%, 2rem);
//  background: transparent;
//  border: 2px solid red;
//  content: '';
//}

.coordinate-question {
  .coordinate-input {
    display: block;
    font-size: 25px;
    input {
      margin-left: 3px;
      margin-right: 3px;
      max-width: 100px;
      display: inline-block;
      @media print {
        &::placeholder {
          color: transparent !important;
        }
      }
    }
  }
}

@mixin feedback-panel-classes($transparency, $alph) {
  .feedback-panel-header {
    background-color: rgba($transparency, 2 * $alph);
    border-radius: 10px 10px 0 0;
    > button {
      color: inherit;
      min-width: 176px;
      flex-basis: 100%;
      @media (max-width: 576px) {
        min-width: 0;
      }
      &:hover {
        color: inherit;
      }
      &.inline-part-jump {
        font-family: inherit;
        font-size: 1rem;
        text-decoration: underline;
        text-decoration-thickness: 0.05rem;
        text-underline-offset: 0.1rem;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .feedback-panel-content {
    background-color: rgba($transparency, $alph);
    border-radius: 0 0 2px 2px;
  }
}

.feedback-panel-light {
  @include feedback-panel-classes(#fff, 0.18);
}

.feedback-panel-dark {
  @include feedback-panel-classes(#000, 0.08);
}

.correct {
  .feedback-panel-dark {
    @include feedback-panel-classes(#000, 0.18);
  }
}

.inline-dropdown {
  min-width: unset !important;
}

.spaced-tooltip br {
    margin-bottom: 0.5rem;
}
