// NOMENSA carousel.scss

.carousel {
  .carousel-item {
    > div {
      display: flex;
      min-height: 550px;
    }
  }
  .carousel-indicators {
    button {
      background-color: transparent;
      border: 2px solid $black;
      border-radius: 50%;
      height: 12px;
      opacity: 1;
      width: 12px;

      &.active {
        background-color: $secondary;
        border-color: $secondary;
      }
    }
  }
  > a[class^='carousel-control-'] {
    display: block;
    cursor: pointer;
    width: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    opacity: 1;
    &:focus, &:hover {
      height: 10%;
      display: block;
      margin-top: auto;
      margin-bottom: auto;
      box-shadow: 0 0 0 2pt black !important;
      border-radius: 2px;
      outline: none !important;
    }
    span {
      opacity: 0;
    }
  }
  > a[class*='next'] {
    right: 3px;
    background-image: url('/assets/common/icons/chevron_right.svg');
  }
  > a[class*='prev'] {
    left: 3px;
    background-image: url('/assets/common/icons/chevron_left.svg');
  }
}
