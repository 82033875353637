// ISAAC

.input-group.is-invalid {
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    &:not(.date-input) {
      border: solid 1px theme-color("danger");
    }
    @include border-radius($input-border-radius, 0);
  }

  .close {
    color: theme-color("danger");
  }
}

.input-group.is-valid {
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: solid 1px theme-color("success");
    pointer-events: none;
    @include border-radius($input-border-radius, 0);
  }

  .close {
    color: theme-color("success");
  }
}

.date-input .is-invalid, .date-input .is-valid {
  background: white;
}

.date-input {
  min-width: 280px;

  select.form-select {
    padding: 0.5rem;
    .board-card & {
      padding: 0.15rem;
    }
    &.is-invalid {
      padding-right: 0.5rem !important;
    }
  }
}

.date-input-day {
  flex-grow: 2 !important;
}

.date-input-month {
  flex-grow: 5 !important;
}

.date-input-year {
  flex-grow: 3 !important;
}

// NOMENSA forms.scss

input {
  &.form-control {
    @include placeholder($gray-500);
    border: solid 1px $black;
    padding: 0 1.2em;
  }
}

textarea {
  &.form-control {
    border: solid 1px $black;
    padding: 0.7rem 1.2rem;// 0;  // TextAreas look wrong with this 0 for bottom padding. Remove it.

    &.answer {
      height: 48px;
    }
  }
}

[type='search'] {
  @include placeholder($black);
  border: 0;
  border-radius: ($border-radius * 10);
  height: 2.6rem;
  padding: initial 40px initial 1rem;

  @include respond-above(sm) {
    max-width: 220px;
  }

  &.form-control {
    &:focus {
      border-color: $gray-160;
      box-shadow: 0 0 0 0.2rem $shadow-25;
    }
  }

  &.search--filter-input {
    border: none;
    border-radius: 8px;
    max-width: 300px;
    width: 100%;
  }
}

button {
  &.search-button {
    height: 36px;
    min-width: auto;
    position: absolute;
    right: 4px;
    top: 2px;
    width: 36px;

    &:hover {
      background-color: transparent;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem $shadow-25;
    }

    svg {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.search--main-group,
.search--filter-group {
  &.form-group {
    margin: 0;
    position: relative;
  }
}

// ISAAC

label.label-radio > div {
  display: inline-block;
  padding-left: 1em;
}

.required-before {
  &:before {
    content: '* ';
    padding-left: 6px;
    font-weight: 600;
    color: $secondary;
  }
}

// Position correctly at all sizes
.form-inline .form-check-input {
  position: relative;
  flex-shrink: 0;
  margin-right: 0.25rem;
  margin-left: 0;
}

// Remove spinner from date picker
input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Used to visually separate the button from the input in an input group (see groups page for example)
.separate-input-group {
  input {
    border-radius: 0.25rem !important;
  }
  button {
    margin-left: 1rem !important;
    border-radius: 50px !important;
  }
}

.inline-form-input-btn {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 0 4px 4px 0 !important;
}

// Bootstrap 5 forms migration
// FIXME: anything using these is old and should be updated -- see https://getbootstrap.com/docs/5.0/migration/#forms

.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  // width: auto;
  vertical-align: middle;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-check-input {
  margin-top: 0;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    > *:not([type='checkbox']) { // checkboxes in BS5 have no content so would be made 0 width
      width: max-content;
    }
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

[type=search] {
  border-radius: 2.5rem;
  height: 2.6rem !important;
}

.text-dark {
  color: #343a40 !important;
}
