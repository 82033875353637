@import "../common/boards";

.board-percent-completed {
  @extend .fw-semi-bold;
  @extend .font-size-1;
  color: #fff;
  z-index: 10;
  text-align: center;
  &:not(.set-assignments) {
    line-height: 48px;
    div {
      line-height: 48px;
    }
  }
  &.set-assignments {
    div {
      padding-top: 6px;
      line-height: 1px;
    }
    small {
      @extend .font-size-0-5;
      line-height: 5px !important;
    }
  }
  width: 48px;
  height: 48px;
}

// Only used for *actual cards* on Ada
.board-card {
  position: relative;
  border-radius: 0;
  border: none;
}

.board-circle {
  outline: none !important;
  border: none !important;
  &:focus-visible {
    outline: 2px solid $focus-blue !important;
    border-radius: 50px !important;
  }
}

.question-progress-icon {
  min-width: 58px !important;
  display: block;
  padding: 14px 0 !important;
  position: relative;
  align-content: center;
  @include media-breakpoint-up(sm) {
    min-width: 86px !important;
  }

  .inner-progress-icon {
    @extend .text-center;
    @extend .px-2;
    position: relative;
    img {
      max-width: 2rem;
      max-height: 2rem;
    }
    .icon-title {
      @extend .font-size-0-75;
      @extend .fw-bold;
      margin-top: 15px;
      color: $cs-jet;
    }
  }
}

.question-link-title {
  color: $cs-jet;
}
