.scroll-btn {
  position: fixed;
  display: none; // If not in use hide
  height: 45px;
  width: 45px;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  right: 10px;
  box-shadow: 2px 2px 10px 0 #0004;
  background-color: #fff !important;
  border-radius: 50%;
  transition: opacity 0.1s linear;
  z-index: 101;
  img {
    margin-top: -4px;
    height: 80%;
    width: 80%;
  }
}

.is-sticky {
  display: flex;
}
