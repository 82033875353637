@import "../common/table";

.table {
  text-align: left;
  th {
    font-weight: bold;
  }
  tr {
    &.disabled {
      background-color: $cs-cultured;
    }
    &.selected {
      background-color: white;
    }
  }
  thead {
    background-color: rgba(0, 0, 0, 0.06);
    tr, th, td {
      background-color: transparent;
    }
    th {
      vertical-align: middle;
      border-bottom: solid 1px $cs-dark-silver !important;
    }
  }
  tr, td, th {
    background-color: $cs-white;
  }

  &:not(.table-borderless) {
    tr, td, th {
      border: solid 1px $cs-dark-silver;
    }
  }
}

.group-table {
  tr, td, th {
    border-left: none !important;
    border-right: none !important;
  }
}

.long-titled-col {
  min-width: 140px;
}
