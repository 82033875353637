tr.board-card {
  height: 100px;
}
.col > .board-card {
  height: calc(100% - 25px);
}
.board-card {
  min-width: 30%;
  @media (max-width: map-get($breakpoints, sm)) {
    min-width: 50%;
  }
  margin-top: 25px;
  .card-footer {
    background: white;
    border-top: unset;
  }
  .card-body > .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    min-width: 20px;
    padding-left: 1px;
  }
  aside {
    position: relative;
    margin-left: 100px;
    margin-top: 14px;
    margin-bottom: 12px;
    .card-subtitle {
      margin-top: 4px;
    }
  }
  .card-title {
    font-size: 1.5rem;
    font-weight: bold;
    a {
      display: inline;
    }
  }
  .card-subtitle {
    margin-bottom: 0.25rem;
  }
  button {
    -webkit-appearance: none;
    border: none;
    outline: none !important;
  }
  .unassign {
    cursor: pointer;
    min-width: 20px;
  }

  button.board-subject-hexagon-container {
    background-color: white;
  }

  // If compsci uses yellow hexagons, need black number for accessibility:
  //.subject-compsci + .board-percent-completed {
  //  color: #0f0f0f;
  //}
  .groups-assigned {
    width: 100%;
    font-size: 12pt;
    line-height: 110%;
    font-weight: bold;
    z-index: 10;
    position: absolute;
    top: 22px;
    left: 1px;
    color: #fff;
    text-align: center;
    strong {
      display: block;
      font-size: 20pt;
      font-weight: 600;
      padding-bottom: 4px;
      margin-top: -6px;
    }
  }
}

.input-align {
  @media only screen and (min-resolution: 192dpi) {
    width: 100%;
    align-self: center;
  }
}

.table-button {
  background: none!important;
  border: none;
  padding: 0!important;
  font-weight: bold;
}

.my-gameboard-table {
  min-width: 870px;
}

.input-options {
  float: right;
  @media only screen and (max-width:800px) {
    float: unset !important;
    flex-direction: column;
  }
}

.delete-button {
  top: -50px;
}

.table-share-link, .card-share-link {
  position: relative;
}

.set-assignments-button {
  width: min-content;
  font-size: 1rem;
}