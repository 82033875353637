@mixin table-sticky() {
  position: sticky !important;
  background-clip:  padding-box !important;
  background: $gray-103;
  z-index: 1;
  top: 0;
}

@mixin after-border() {
  content:'';
  position:absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.list-gameboard {
  .bg-success {
    background-color: #dfefd7 !important;
    a > span:first-of-type {
      border-color: #ddd;
    }
  }

  .gameboard-item-icon {
    max-width: 2rem;
    max-height: 2rem;
  }

  .gameboard-item-message {
    font-size: 0.875rem;
    font-weight: normal;
    color: $gray-160;
    align-self: center;
    padding-left: 1.5rem;
  }

  .gameboard-item-message-phy {
    font-size: 0.875rem;
    font-weight: normal;
    color: #000000;
    align-self: center;
    padding-left: 1.5rem;
    text-transform: uppercase;
  }
}


.hierarchy-tags {
  font-size: 0.875rem;
  color: $gray-160;
  align-self: center;

  .hierarchy-tag:not(:first-child) {
    padding-left: 12px;
    
    &::before {
      content: '|';
      height: 10px;
      margin-left: -7px;
      width: 10px;
      position: absolute;
    }
  }
}

.responsive {
  overflow: auto;
  display: block;
  .table.table-bordered {
    tr {
      td {
        .custom-control-label {
          &:before, &:after {
            width: 1.5rem;
            height: 1.5rem;
            margin-top: -0.25rem;
          }
        }

        .img-center {
          display: flex;
          justify-content: center;
          input {
            height: 2rem;
          }
        }
      }
    }
  }
}

.my-gameboards-filters {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  &.shown {
    max-height: 144px;
  }
  @include media-breakpoint-up(lg) {
    transition: max-height 0.2s ease-in-out;
    &.shown {
      max-height: 72px;
    }
  }
}

.my-gameboards-cards-hex-container {
  height: 125px;
  text-align: center;
  .col {
    position: relative;
    .board-subject-hexagon-container {
      position: relative;
    }  
  }
}

.gameboards-filter-dropdown {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.3rem 0.8rem !important;
  border-radius: 0.5rem;
  min-width: unset;
  font-size: unset;
  &::after {
    content: url(/assets/common/icons/chevron_down.svg);
    filter: invert(1);
    transform: rotate(-90deg);
    transition: transform 0.2s ease-in-out;
    display: block;
    width: 100%;
    height: 100%;
  }
  &.selected::after {
    transform: rotate(0deg);
  }
}
