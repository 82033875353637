#question-hexagons {
  :focus {
    outline: none !important;
    polygon {
      stroke: black;
    }
    text {
      fill: black;
      font-weight: bolder;
    }
  }
}
