// From NOMENSA global.scss
.search--filter-group.form-group input.form-control {
  border-width: 0.5px !important;
  border-color: $gray-160;
}

/** Focus styles **/

.form-control:focus, .form-select:focus {
  box-shadow: none;
  outline: 0.2rem solid $focus-blue;
}

.form-control-file:focus {
  outline: none;
  border: 0.2rem solid $focus-blue;
  border-radius: 5px;
}

.form-check-input:focus {
  outline: 0.2rem solid $focus-blue;
}

.page-link:focus {
  box-shadow: none;
  border: 0.2rem solid $focus-blue;
  border-radius: 3px;
}

.navbar-toggler:focus {
  box-shadow: none;
  border: 0.2rem solid $focus-blue;
  border-radius: 3px;
}

.link-list.a:focus {
  box-shadow: none !important;
  outline: 0.2rem solid $focus-blue !important;
}

.navbar-toggler:focus {
  box-shadow: none;
  outline: 0.2rem solid $black;
}

.nav-link.active:focus {
  box-shadow: none;
  outline: 0.2rem solid $secondary;
}

.book-chapter:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 1px;
  outline: none !important;
}

.nav-link:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 1px;
  outline: none !important;
}

.book-link:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 0;
  outline: none !important;
}

.login-b:focus {
  box-shadow: none;
  border: 0.2rem solid $focus-blue;
}

// Close modal button
.close:not(:disabled):not(.disabled):focus {
  box-shadow: none;
  outline: 0.2rem solid $focus-blue;
}

::placeholder { // Firefox, Chrome, Opera
  color: $gray-160 !important;
}

:-ms-input-placeholder { // Internet Explorer 10-11
  color: $gray-160 !important;
}

::-ms-input-placeholder { // Microsoft Edge
  color: $gray-160 !important;
}

:focus {
  outline: 0.2rem solid $black;
}

// TODO: Review blanket suppression of focus indicator on keyboard-unfocusable elements.
[tabindex="-1"]:focus {
  outline: 0 !important;
}

/** End of focus styles **/
