.google-button-logo {
  padding-right: 1.5rem;
  transform: translate(0, -15%);
}

#login-modal-form {
  max-height: 800px;
  @media (min-height: 800px) {
    max-height: 90vh;
  }
  padding: 20vh 30px;
  @include media-breakpoint-down(lg) {
    max-height: unset;
    padding-top: 20px;
    padding-bottom: 30px;
  }
}

.hr-or {
  margin-top: 25px;
  margin-bottom: 25px;
  &::before {
    font-family: "exo_2regular", sans-serif;
    content: "or";
    bottom: -12px;
    background: white;
    border-bottom: none;
    width: 50px;
  }
}

// NOMENSA login.scss LIVES IN ISAAC'S elements.scss
