@import "../common/filter";

#filter-panel {
  max-width: 892px !important;
  position: relative;
  z-index: 1;
  border-radius: 0;
  border: none;
  .card-body {
    background-color: $cs-white;
  }
  .card-footer {
    border-radius: 0 !important;
    background: $dark-pink-300;
  }
  &:before {
    position: absolute;
    z-index: -1;
    content: " ";
    width: calc(100% + 400px);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-size: 100% 100%;
    background-image: url("/assets/cs/decor/dots-circle-bg.svg");
    height: 100%;
  }
}

#gameboard-generator {
  .list-group-links {
    .list-group-item:first-of-type {
      border-top: $cs-list-outline-gray solid 1px !important;
    }
  }
}

$shapes: hex, square, octagon, diamond, circle;

svg {
  @each $shape in $shapes {
    .#{$shape} {
      &:not(.active) {
        fill: $cs-silver;
        stroke: none;
      }

      &.mini:not(.active) {
        fill: none;
        stroke: none;
      }

      &.none {
        fill: none;
        stroke: none;
      }

      &.clickable {
        cursor: pointer;
      }

      &.active.difficulty.practice {
        fill: $yellow-300;
        stroke: $cs-black;
        stroke-width: 1px;
      }

      &.active.difficulty.challenge {
        fill: $cyan-300;
        stroke: $cs-black;
        stroke-width: 1px;
      }

      &.filter-count {
        fill: $pink-300;
        opacity: .05;
      }

      &:focus {
        outline: none;
        stroke: black !important;
        stroke-width: 0.2rem;
      }
    }
  }
}

.difficulty-icon-title {
  color: $cs-black;
  &.active {
    color: $cs-black;
  }
}

