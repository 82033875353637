@font-face {
  font-family: 'desyrel';
  src: url('/assets/common/fonts/desyrel-webfont.woff') format('woff'),
  url('/assets/common/fonts/desyrel-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins-bold';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/cs/fonts/poppins-bold.woff2') format('woff2'),
  url('/assets/cs/fonts/poppins-bold.woff') format('woff'),
  url('/assets/cs/fonts/poppins-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'poppins-bolditalic';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/cs/fonts/poppins-bolditalic.woff2') format('woff2'),
  url('/assets/cs/fonts/poppins-bolditalic.woff') format('woff'),
  url('/assets/cs/fonts/poppins-bolditalic.ttf') format('truetype');
}

@font-face {
  font-family: 'poppins-italic';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/cs/fonts/poppins-italic.woff2') format('woff2'),
  url('/assets/cs/fonts/poppins-italic.woff') format('woff'),
  url('/assets/cs/fonts/poppins-italic.ttf') format('truetype');
}

@font-face {
  font-family: 'poppins-light';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/cs/fonts/poppins-light.woff2') format('woff2'),
  url('/assets/cs/fonts/poppins-light.woff') format('woff'),
  url('/assets/cs/fonts/poppins-light.ttf') format('truetype');
}

@font-face {
  font-family: 'poppins-lightitalic';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/cs/fonts/poppins-lightitalic.woff2') format('woff2'),
  url('/assets/cs/fonts/poppins-lightitalic.woff') format('woff'),
  url('/assets/cs/fonts/poppins-lightitalic.ttf') format('truetype');
}

@font-face {
  font-family: 'poppins-regular';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/cs/fonts/poppins-regular.woff2') format('woff2'),
  url('/assets/cs/fonts/poppins-regular.woff') format('woff'),
  url('/assets/cs/fonts/poppins-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'poppins-semibold';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/cs/fonts/poppins-semibold.woff2') format('woff2'),
  url('/assets/cs/fonts/poppins-semibold.woff') format('woff'),
  url('/assets/cs/fonts/poppins-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'poppins-semibolditalic';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/cs/fonts/poppins-semibolditalic.woff2') format('woff2'),
  url('/assets/cs/fonts/poppins-semibolditalic.woff') format('woff'),
  url('/assets/cs/fonts/poppins-semibolditalic.ttf') format('truetype');
}

@font-face {
  font-family: 'ubuntu-mono-regular';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/cs/fonts/ubuntu-mono-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ubuntu-mono-bold';
  font-weight: normal;
  font-style: normal;
  src: url('/assets/cs/fonts/ubuntu-mono-bold.ttf') format('truetype');
}
