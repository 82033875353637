@import "../common/elements";

.text-block {
  h2, p {
    margin-bottom: 1.2rem;
  }
}

.image-block {
  object-fit: contain;
  @extend .vertical-center;

  > img {
    width: 100%;
  }
}

.text-theme {
  @extend .text-secondary;
}

.bg-theme {
  @extend .bg-secondary;
}

.border-theme {
  @extend .border-secondary;
}

.bg-dark-pink-200 {
  background-color: $dark-pink-200;
}

.bg-dark-pink-300 {
  background-color: $dark-pink-300;
}

.bg-cyan-200 {
  background-color: $cyan-200;
}

img.full-background-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}
