@import "../common/search";

#header-search-group {
  height: 48px;
  min-width: 100%;
  #header-search {
    min-width: calc(100% - 18px - 30px);
    flex: auto !important;
    min-height: 100%;
    border-radius: 24px 0 0 24px;
    border: none;
  }
  button {
    padding-left: 15px;
    padding-right: 15px;
    img {
      width: 18px;
      height: 18px;
    }
    background: $pink-300;
    border-radius: 0 24px 24px 0;
  }
}