#reservation-modal {
    table {
        input[type=checkbox] {
            z-index: 3 !important;
        }
        &.reserved {
            th {
                &.checkbox {
                    width: 5%;
                }

                &.student-name {
                    width: 50%;
                }

                &.reserved-by {
                    width: 17%;
                }
            }
        }
        td .custom-checkbox {
            margin-left: 14px;
        }
    }
}
