@-webkit-keyframes gutter-overflow {
  0%  {width: 100%; @include make-col-offset(0, 8);}
  60%  {width: 100%; @include make-col-offset(0, 8);}
  100% {width: 150%; @include make-col-offset(-2, 8);}
}
@-moz-keyframes gutter-overflow {
  0%  {width: 100%; @include make-col-offset(0, 8);}
  60%  {width: 100%; @include make-col-offset(0, 8);}
  100% {width: 150%; @include make-col-offset(-2, 8);}
}
@-o-keyframes gutter-overflow {
  0%  {width: 100%; @include make-col-offset(0, 8);}
  60%  {width: 100%; @include make-col-offset(0, 8);}
  100% {width: 150%; @include make-col-offset(-2, 8);}
}
@keyframes gutter-overflow {
  0%  {width: 100%; @include make-col-offset(0, 8);}
  60%  {width: 100%; @include make-col-offset(0, 8);}
  100% {width: 150%; @include make-col-offset(-2, 8);}
}

@include media-breakpoint-up(lg) {
  .expansion-layout {
    -webkit-animation: gutter-overflow 0.5s; /* Safari 4+ */
    -moz-animation:    gutter-overflow 0.5s; /* Fx 5+ */
    -o-animation:      gutter-overflow 0.5s; /* Opera 12+ */
    animation:         gutter-overflow 0.5s; /* IE 10+, Fx 29+ */
    @include make-col-offset(-2, 8);
    width: 150%;
  }
}
