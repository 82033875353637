// ISAAC

figure {
  background-color: white;
  img {
    max-width: 100%;
    padding: 1rem;
    padding-bottom: 2rem;
  }
}

.figure-caption {
  font-size: 1rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  background-color: $gray-103;
  p {
    margin: 0;
  }
}
.figure-reference {
  font-weight: bold;
}

@include respond-above(md) {
  .align-start {
    float: left;
    width: 300px;
    padding-right: 30px;
  }

  .align-end {
    float: right;
    width: 300px;
    padding-left: 30px;
  }

  .align-end-half {
    float: right;
    width: 50%;
    padding-left: 30px;
  }
}

.text-center-column {
  text-align: left;
  @include respond-above(sm) {
    padding-left: 20%;
    padding-right: 20%;
  }
}

// NOMENSA figures.scss
.img-thumbnail {
  border-width: 0;
}

figure {
  box-shadow: 0 2px 30px 0 $shadow-08;

  .figure-caption {
    border-top: 1px solid $gray-110;
    color: $black;
    font-size: .8125rem;
    padding: .5rem;
  }
}
