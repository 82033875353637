.hexagon-tier-title,
.hexagon-level-title,
.hexagon-tier-summary,
.difficulty-title,
.filter-count-title {
  pointer-events: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: $secondary-font-semi-bold;
}
.hexagon-level-title {
  font-weight: 600;
  font-size: 1rem;
  &.active {color: white;}
}
.hexagon-tier-title {
  //font-weight: 600;
  font-size: 0.8rem;
  color: #333;
  hyphens: manual;
  &.active {color: white;}
  &.small {
    font-size: 0.75rem;
    overflow-wrap: anywhere;
  }
  &.de-emph {
    color: #33333344;
  }
}
.hexagon-tier-summary {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  font-weight: 100;
}

.difficulty-title, .filter-count-title {
  font-weight: 400;
  font-size: 0.92rem;
  &.active {color: white;}
  &.difficulty-icon-title {
    font-weight: 600;
    font-size: 0.8rem;
  }
}
