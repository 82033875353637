// NOMENSA button.scss
.btn.plus-button {
  position: relative;
  padding-right: 52px;
  &::after {
    @include pseudo-element();
    background-image: url("/assets/common/icons/add.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
    height: 100%;
    right: 26px;
    top: 0;
    bottom: 0;
    width: 20px;
  }
}

.quick-question-options {
  border-radius: 5px;
}

.border-muted {
  border-color: grey !important;
}

// When reactstrap hides the warning text
@media (max-width:575px) {
    .manage-tests-btns {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center !important;

        button, a {
            width: 100%;
            margin-bottom: 0.5rem;
        }
    }
}

// What on earth does this do and why when I remove it does it break input addon buttons?
button {
  -webkit-appearance: none;
  border: none;
  outline: none !important;
}

.btn.btn-primary:focus-visible, .btn.btn-outline-primary:focus-visible {
  background-color: unset !important;
}

.btn-close {
  align-self: center;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn.btn-inline-link {
  font-family: unset;
  font-size: unset;
  letter-spacing: unset;
  min-width: unset;
  text-decoration: underline;
  vertical-align: top;
  padding: 0 !important;
  border: none;
  &:hover {
    text-decoration: none;
  }
}
