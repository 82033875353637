@import "../common/assignment-progress";

.assignment-progress-group .group-name {
  @extend .py-2;
  padding-left: 13px;
  background: $yellow-300;
  .icon-group {
    width: 25px;
    min-width: 25px;
    height: 1.75rem;
    background-size: contain;
  }
}

.assignment-progress-gameboard .gameboard-header:not(.text-muted) > *:first-child:before {
  background: $yellow-300 !important;
  color: black;
  text-decoration: none !important;
}

.progress-questions {
  font-size: 1.1rem;
}

.progress-header {
  font-size: 1.0rem;
}

.assignment-progress-gameboard .gameboard-title {
  //@extend .fw-regular;
  font-size: 0.9rem;
  color: black;
}

.gameboard-links a, .download-csv-link {
  @extend .btn;
  @extend .btn-link;
  @extend .fw-regular;
  font-size: 0.9rem;
}

.accordion-arrow {
  @extend .mx-2;
}

.assignment-count-pill {
  @include pill-tag(black, white);
  min-width: 25px;
  text-align: center;
  border-radius: 8px;
}
