// Ada brand and visual identity colours (see Brand Book)
// Stage colours - OLD
// $gcse-colour: #C40E50;
// $gcse-colour-50: #E186A7;
// $gcse-colour-25: #F0C3D3;
// $all-stages-colour: #9148BC;
// $all-stages-colour-50: #C8A3DD;
// $all-stages-colour-25: #E3D1EE;
// $a-level-colour: #2146AD;
// $a-level-colour-50: #90A2D6;
// $a-level-colour-25: #C7D1EA;

// Greyscale
$cs-black: #000000;
$cs-jet: #333333;
$cs-muted: #4D575C;
$cs-dark-silver: #B9B9B9;
$cs-silver: #C2C2C2;
$cs-light-gray: #D6D6D6;
$cs-list-outline-gray: #EBEBEB;
$cs-cultured: #F5F5F5;
$cs-white: #ffffff;

// FIXME the 100-700 scale is upside-down... needs fixing

// Dark pinks
$dark-pink-100: #510937;
$dark-pink-200: #6C0A48;
$dark-pink-300: #870D5A; // theme colour
$dark-pink-400: #9E2372;
$dark-pink-500: #D94BAA; // black text this and below
$dark-pink-600: #F07DD2;
$dark-pink-700: #FFD2F0;

// Pinks
$pink-100: #A8007A;
$pink-200: #E11EA2; // black text this and below
$pink-300: #FF4DC9; // theme colour
$pink-400: #FF73DC;
$pink-500: #FFA0F2;
$pink-600: #FFC4FA;
$pink-700: #FFF0FF;

// Cyans - all black text
$cyan-100: #00A8A6;
$cyan-200: #00D2C0;
$cyan-300: #05FFE7; // theme colour
$cyan-400: #8CFFFA;
$cyan-500: #AFFFFF;
$cyan-600: #D0FFFF;
$cyan-700: #EBFFFF;

// Yellows - all black text
$yellow-100: #DCAA3C;
$yellow-200: #F5C254;
$yellow-300: #FFE672; // theme colour
$yellow-400: #FFF596;
$yellow-500: #FFFAC3;
$yellow-600: #FFFFE6;
$yellow-700: #FFFFF5;

$cs-perfect: #05611E;
$cs-in-progress: #FE8E15;
$cs-failed: #A90212;


//Text Colour
$highlight-text-light: $cs-white;
$highlight-text-dark: $cs-black;

// Content highlight classes, doubled up for specificity
.hi-dark-pink.hi-dark-pink {
    background-color: $dark-pink-100;
    color: $highlight-text-light;
    tr, td, th {
        background-color: $dark-pink-100;
        color: $highlight-text-light;
    }
}
.hi-dark-pink-50.hi-dark-pink-50 {
    background-color: $dark-pink-400;
    color: $highlight-text-light;
    tr, td, th {
        background-color: $dark-pink-400;
        color: $highlight-text-light;
    }
}
.hi-dark-pink-25.hi-dark-pink-25 {
    background-color: $dark-pink-400;
    color: $highlight-text-dark;
    tr, td, th {
        background-color: $dark-pink-400;
        color: $highlight-text-dark;
    }
}
.hi-cyan.hi-cyan {
    background-color: $cyan-100;
    color: $highlight-text-dark;
    tr, td, th {
        background-color: $cyan-100;
        color: $highlight-text-dark;
    }
}
.hi-cyan-50.hi-cyan-50 {
    background-color: $cyan-400;
    color: $highlight-text-dark;
    tr, td, th {
        background-color: $cyan-400;
        color: $highlight-text-dark;
    }
}
.hi-cyan-25.hi-cyan-25 {
    background-color: $cyan-600;
    color: $highlight-text-dark;
    tr, td, th {
        background-color: $cyan-100;
        color: $highlight-text-dark;
    }
}
.hi-pink.hi-pink {
    background-color: $pink-100;
    color: $highlight-text-light;
    tr, td, th {
        background-color: $pink-100;
        color: $highlight-text-light;
    }
}
.hi-pink-50.hi-pink-50 {
    background-color: $pink-400;
    color: $highlight-text-dark;
    tr, td, th {
        background-color: $pink-400;
        color: $highlight-text-dark;
    }
}
.hi-pink-25.hi-pink-25 {
    background-color: $pink-600;
    color: $highlight-text-dark;
    tr, td, th {
        background-color: $pink-600;
        color: $highlight-text-dark;
    }
}
.hi-yellow.hi-yellow {
    background-color: $yellow-100;
    color: $highlight-text-dark;
    tr, td, th {
        background-color: $yellow-100;
        color: $highlight-text-dark;
    }
}
.hi-yellow-50.hi-yellow-50 {
    background-color: $yellow-400;
    color: $highlight-text-dark;
    tr, td, th {
        background-color: $yellow-400;
        color: $highlight-text-dark;
    }
}
.hi-yellow-25.hi-yellow-25 {
    background-color: $yellow-600;
    color: $highlight-text-dark;
    tr, td, th {
        background-color: $yellow-600;
        color: $highlight-text-dark;
    }
}

$primary: $dark-pink-300;
$secondary: $dark-pink-300;
$dark-primary: $pink-300;
$dark-secondary: $pink-300;
$tertiary: $cyan-300;

// Override Bootstrap warning alert text colour - it doesn't look like we can set it in the theme
.alert-warning {
    color: $cs-black !important;
}

// Inequality colours
$inequality-primary: $cs-light-gray;
$inequality-stroke: transparent;
$inequality-background: $cs-list-outline-gray;
$inequality-item-colour: $primary;
$inequality-item-font-colour: $cs-white;

// Assignment progress colours
$default-name-fill: $cs-jet;
$perfect-fill: $cs-perfect;
$passed-fill: $cs-jet;
$in-progress-fill: $cs-in-progress;
$failed-fill: $cs-failed;


// fonts
$primary-font: "poppins-regular", sans-serif;
$secondary-font: 'poppins-regular', sans-serif;
$secondary-font-bold: 'poppins-bold', sans-serif;
$secondary-font-semi-bold: 'poppins-semibold', sans-serif;
$secondary-font-italic: 'poppins-italic', sans-serif;
$secondary-font-medium: $secondary-font;
$tertiary-font: 'poppins-light', sans-serif;

$question-line-height: 1.5rem;
$question-padding: 3rem;
$question-font-size: 1rem;
$question-font-weight: 400;

$text-muted: $cs-dark-silver;


// Colour Overrides
// FIXME overriding base Bootstrap colour definitions is not the right way to go about this.
//  These are shared with and redefined by Physics.
$purple: $dark-pink-300; // Something to do with links

// Stage colours
// Used for staged accordion sections - we currently do not distinguish by colours
$gcse-colour: $pink-400;
$a-level-colour: $cyan-400;
$national-5-colour: $pink-400;
$higher-colour: $dark-pink-400;
$adv-higher-colour: $cyan-400;
$core-colour: $pink-400;
$advanced-colour: $cyan-400;
$all-stages-colour: $yellow-400;

// Button/input colours
$light-hover-dark-pink: $dark-pink-200;
$light-active-dark-pink: $dark-pink-100;
$dark-hover-pink: $pink-200;
$dark-active-pink: $pink-100;
$focus-blue: $cyan-100; // focus indicator colour

// Greys - All used in various places, mainly for borders
$gray-103: $cs-cultured;
$gray-107: $cs-list-outline-gray;
$gray-110: $cs-light-gray;
$gray-118: $cs-light-gray;
$gray-120: $cs-silver;
$gray-136: $cs-dark-silver;
$gray-160: #666; // FIXME no palette replacement for this one that is close enough
$gray-194: $cs-jet;

$gray-blue: #636c73; // FIXME BEWARE this is used in breadcrumb colours, but we don't use breadcrumbs on Ada yet

// Shadows
$shadow-08: rgba(0, 0, 0, 0.08); // Used in various places
$shadow-25: rgba(0, 0, 0, 0.25); // Used by forms
$shadow-3: rgba(0, 0, 0, 0.3); // Used by buttons
$shadow-black: rgb(0, 0, 0); // Used by buttons

// ISAAC
// Node module imports
@import "~bootstrap/scss/functions";

$nav-link-hover-color: $cs-jet;
$border-color: $cs-black;
// bootstrap 4 alert-warning colours
$warning-bg-subtle: #fffef3;
$warning-border-subtle: #fffeee;
$toast-padding-x: 1.5rem;
$border-color: $cs-light-gray;

// all Bootstrap !default overrides must appear before this; anything that uses the defaults must come after
@import "~bootstrap/scss/variables"; 

// Theme
$theme-colour-overrides: (
    // Primary and secondary are the same - we use other properties (outline) to distinguish primary/secondary buttons
    "primary": $primary,
    "secondary": $secondary,
    "warning": $yellow-500,
    "dark-primary": $dark-primary,
    "dark-secondary": $dark-secondary,
    "info": $tertiary,

    "turquoise-blue": $cyan-300,
    "pink" : $pink-300,
    "dark-pink" : $dark-pink-300,
    "yellow" : $yellow-300,
    "jet-grey" : $cs-jet,
    "silver-grey" : $cs-silver,
    "light-grey" : $cs-light-gray,
    "cultured-grey" : $cs-cultured,

    "perfect": $cs-perfect,
    "in-progress": $cs-in-progress,
    "failed": $cs-failed,
);
$theme-colors: map-merge($theme-colors, $theme-colour-overrides);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  nav: 1256px,
  // xxl: 1400px // we don't need xxl
);

$element-sizes: (
    5: 5%, 
    10: 10%,
    20: 20%,
    30: 30%,
    40: 40%,
    50: 50%,
    60: 60%,
    70: 70%,
    80: 80%,
    90: 90%,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  // xxl: 1320px, // for large screens we keep the single-column layout and only expand the margin, so we don't use a larger container
);

$spacer: 1rem !default;
$spacers: map-merge($spacers, ( // Special Bootstrap variable that is used to generate spacing classes (e.g. pd-md-6)
    6: ($spacer * 6.25) // 100px-ish
));

$enable-negative-margins: true;

// all Bootstrap overrides must appear before this
@import "~bootstrap/scss/bootstrap"; 
@import "~katex/dist/katex.min.css";
@import "~bootstrap-block-grid/dist/bootstrap4-block-grid.min.css";
@import "~billboard.js/dist/theme/insight.css";
@import "~highlight.js/styles/a11y-light.css";
@import "~leaflet/dist/leaflet.css";

@import "../common/isaac";

// Utility
@import "../common/mixins";
@import "../common/_utils";

// Atoms
@import "fonts";
@import "typography";
@import "../common/modals";
@import "questions";
@import "forms";
@import "elements";
@import "breadcrumbs";
@import "../common/media";
@import "table";
@import "tabs";
@import "accordions";
@import "../common/cookies";
@import "cards";
@import "list-groups";
@import "inequality-modal";
@import "../common/carousel";
@import "icons";
@import "boards";
@import "../common/focus";
@import "progress-bar";
@import "../common/anvil";
@import "../common/filter";
@import "../common/reservation-modal";
@import "../common/fasttrack";
@import "llm-questions";
@import "../common/scroll";
@import "../common/checkbox";
@import "../common/toggle";
@import "button";
@import "logo";
@import "featured-profile";
@import "../common/code";

// Navigation
@import "header";
@import "search";
@import "footer";
@import "../common/scroll-button";

// Pages
@import "pages";
@import "homepage";
@import "login";
@import "../common/registration";
@import "../common/assignments";
@import "my-account";
@import "../common/topic";
@import "../common/my-progress";
@import "../common/gameboard";
@import "assignment-progress";
@import "../common/books";
@import "../common/groups";
@import "../common/events";
@import "../common/glossary";
@import "quiz";
@import "../common/callout";
@import "finder";
@import "topics";
@import "resources";

@import "expansion-layout";

// Print
@import "../common/print";

// Difficulty icons
@import "filter";

a.disabled {
    color: $gray-600;
    pointer-events: none;
    cursor: default;
}

.list-group-item {
    border-width: 0;
    width: 100%;
}

p small {
    border: 0;
}

.bg-light-grey {
    > div {
        background: $cs-cultured;
        position: relative;
        z-index: 1;
    }
}

// Double class to give this more specificity
.katex.katex { font-size: 1.1em; }

body {
    color: $black;
    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: $cs-cultured;
}

.content-body {
    background-color: $cs-cultured;
}

// Not used yet, might be nice to add in the future
.pattern-1 {
    background-image: url('/assets/cs/decor/dots-circle.svg');
    background-position: -100px 100px;
    background-size: 1000px;
    background-repeat: no-repeat;
}

.pattern-ada-dots {
    background-image: url('/assets/cs/decor/dots-circle-bg.svg');
    background-position: 0% 160%;
    background-size: 300%;
    background-repeat: no-repeat;
}

.isaac-spinner-sm {
    width: 1.5rem;
    height: auto;
    border-width: 2rem;
}

.isaac-spinner-md {
    width: 2.5rem;
    height: auto;
    border-width: 3rem;
}

.isaac-spinner-lg {
    width: 3.5rem;
    height: auto;
    border-width: 4.5rem;
}
