#account-page {
  .card {
    box-shadow: 0 2px 30px 0 $shadow-08;
    border: none;
  }
  .card-footer {
    background-color: white;
    border-color: $gray-118;
  }

  .selection-area{
    overflow: auto;
    max-height: 180px;
  }

  legend {
    font-size: 1rem;
  }

  .btn.form-control {
    width: 65%;
  }

  box-shadow: none;
}

.react-select-error {
  box-shadow: 0 0 0 1px #dc3545 !important;
  outline: none !important;
  border-radius: 3px;
  .select__control {
    border: none !important;
  }
}

.remove-stage-container {
  min-width: 16px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-dropdown {
  flex: 1 1 0px;
  @include respond-above(xs) {
    min-width: 140px;
  }
}

.invalid-school {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.form-group.school {
  position: relative;

  .school-input {
    position: relative;
  }

  .school-search-results {
    position: absolute;
    z-index: 1;
    margin-top: -0.7rem;
    padding: 0;
    width: 100%;
    border: 1px solid black;
    border-top: 1px solid $gray-103;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @include respond-above(sm) {
      background-color: $white;
      box-shadow: 0 20px 30px 0 $shadow-08;
      top: 5rem;
    }

    li {
      list-style-type: none;
      padding: 0.5em 1em;
      margin: 0;
      background: white;
      cursor: default;
      &:hover {
        background: $primary;
      }
    }
  }
}

.connect-list {
  border: #333 1px solid;
  padding: 0;

  .connect-list-header {
    background-color: #333;
    display: flex;
    align-items: center;

    h4 {
      vertical-align: middle;
      font-size: 1rem;
      color: #fff;
      padding: 1rem;
      margin: 0;
    }
  }

  .search-toggler-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 15px;
    float: right;
    background-color: #333;
    vertical-align: middle;
    background-image: url('/assets/cs/icons/search-white.svg');
    background-repeat: no-repeat;
  }

  .connections-search {
    margin: 12px 0;
    height: 1.8rem;
    margin-left: 1rem;
    width: 80%;
    border-radius: 20px;
  }

  .remove-link {
    text-align: center;
    margin: 0;
    padding: 0.5rem;
    border-top: 1px solid rgba(#333, 0.2);
    a {
      font-weight: bold;
      color: $danger;
    }
  }
}
.teachers-connected {
  margin: 1rem;
  padding-bottom: 0;

  li {
    list-style: none;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .connections-fixed-length-text {
    display: inline-block;
    max-height: 24px;
    width: max-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.connection-inactive {
      max-width: calc(100% - 60px); // include space for "(inactive)" (
    }
  }
}
.revoke-teacher {
  float: right;
}
.reconnect-teacher {
  float: right;
}

.membership-status-help-button {
  left: 25px;
}

.my-groups-table-section {
  table {
    width: 100%;
    border-collapse: collapse;
    border: solid 1px #333;
    margin-bottom: 0;
    th{
      background-color: #333 !important;
      color:#fff;
      font-size:1rem;
    }
    td li {
      color: #858585;
      font-size: .875rem;
      text-align: left;
    }
  }
  .my-groups-table-row-container {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 1.25rem;
    border: #e3e3e3 1px solid;
    border-top: 0;
    table {
      border: none;
    }
    & ul {
      margin-bottom: 0px;
    }
  }
}

.show-password-button {
  min-width: 60px;
  margin-left: 5px;
  padding-top: 7px;
  border-radius: 4px;
}

.linked-account-button {
  border: none;
  width: 46px;
  height: 46px;

  &.google-button {
    padding: 10px;
    background: url("/assets/common/logos/google-g-icon-light.svg") center no-repeat content-box;
  }
}

.qrcode-mfa {
  img {
    width: 80%;
  }
}

#dob-input .date-input-wrapper select {
  padding: 0 1.2em;
}
