// NOMENSA logo.scss
.header-logo {
  float: left;

  @include respond-above(nav) {
    float: none;
  }

  img {
    width: auto;
    height: 3rem;
    @include respond-above(nav) {
      height: 4rem;
      float: none;
    }
  }
}
