@import "../common/my-account";

.linked-account-button-outer {
  border: 3px $cs-cultured solid;
  border-radius: 10px;

  > label, > button {
    width: max-content;
  }
}

.linked-account-button {
  &.rpf-button {
    padding: 5px;
    background: url("/assets/common/logos/raspberry-pi.png") center no-repeat content-box;
    background-size: contain !important;
  }
}

.remove-stage-container {
  min-width: 24px;
  margin: 6px 0 0 6px;
  > button:disabled {
    @extend .text-muted;
  }
}

.my-account-tab {
  select.form-select, input.form-control, div.form-control {
    height: 45px;
    border-radius: 10px !important;
    border: solid 2px #666;
  }

  select.form-select:not(.date-input-day) {
    position: relative;
    appearance: none;
  }

  #dob-input > .date-input-wrapper {
    select {
      padding: 0 1.2em;
      border-radius: 0;
    }

    &:first-of-type select {
      border-radius: 10px 0 0 10px;
    }

    &:last-of-type select {
      border-radius: 0 10px 10px 0;
    }
  }
}

.connections-fixed-length-container {
  max-width: calc(100% - 88px);

  > .icon-person-active {
    min-width: 1.25rem;
  }
}
