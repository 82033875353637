@use "sass:math";
@import "../common/cards";

// number of news cards per line for each breakpoint
$news-cards-per-line: (
        xs: 2,
        lg: 3,
        xl: 4
);

@each $name, $breakpoint in $grid-breakpoints {
  @if (map-has-key($news-cards-per-line, $name)) {
    @include media-breakpoint-up($name) {
      .card-deck .news-card {
        flex: 0 0 calc(#{math.percentage(math.div(1, map-get($news-cards-per-line, $name)))} - #{$grid-gutter-width});
        width: 100%;
      }
    }
  }
}

// number of cards per line for each breakpoint
$cards-per-line: (
        xs: 1,
        md: 2,
        xl: 3
);
@each $name, $breakpoint in $grid-breakpoints {
  @if (map-has-key($cards-per-line, $name)) {
    @include media-breakpoint-up($name) {
      .card-deck .cs-card {
        flex: 0 0 calc(#{math.percentage(math.div(1, map-get($cards-per-line, $name)))} - #{$grid-gutter-width});
      }
    }
  }
}

.card-container {
  padding-bottom: $grid-gutter-width;
}

.cs-card-container {
  container: cs-card / inline-size;
}

.cs-card {
  box-shadow: 0px 0px 2px 1px $shadow-08;
  border-radius: 1rem 1rem 3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  > img {
    aspect-ratio: 16/9;
    border-radius: 1rem 1rem 0 0;
    object-fit: cover;
  }
}

@container cs-card (min-width: 560px) {
  .cs-card {
    flex-direction: row-reverse;

    > div {
      width: 50%;
    }

    > img {
      width: 50%;
      border-radius: 0 1rem 3rem 0;
    }
  }
}

.icon-card {
  box-shadow: 0px 0px 2px 1px $shadow-08;
  border-radius: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  > img {
    width: 25px;
    height: 25px;
    margin-top: 2rem;
    margin-left: 1.5rem;
  }

  .icon-card-tag {
    position: absolute;
    background-color: $pink-400;
    height: 44px;
    margin-top: -22px;
    right: 2rem;
    padding: 0.8rem 1rem;
    border-radius: 2rem;
  }
}

.icon-card-container {
  container: icon-card / inline-size;
  max-width: unset;
}

a.icon-card, a.cs-card {
  text-decoration: none !important;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

@container icon-card (min-width: 480px) {
  .icon-card {
    flex-direction: row;

    > .icon-card-main-content > * {
      padding-left: 1rem !important;

      h3 {
        margin-top: 8px;
      }
    }
  }
}

.cs-card, .icon-card {
  .card-footer {
    .btn-link::after {
      content: "";
      display: inline-flex;
      @include svg-color(url('/assets/common/icons/chevron_right.svg'), $dark-pink-300, 6px, 8px);
      margin-top: -3px;
      margin-left: 10px;
      mask-size: cover;
    }
  }

  .external-link::after {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: -1px -4px 0 4px;
    background: url(/assets/cs/icons/external-link.svg) no-repeat center center;
    vertical-align: middle;
  }
}

.cs-card-plain, .news-card {
  position: relative;
  border-radius: 15px;
  display: inline-block;
  height: 100%;

  &::before {
    content: " ";
    position: absolute;
    top: -28px;
    left: 24px;
    z-index: 2;
    background-repeat: no-repeat;
  }

  &.backslash-1 {
    &::before {
      height: 56px;
      width: 28px;
      background-image: url(/assets/cs/decor/backslash-outline-1.svg);
    }
  }

  &.backslash-2 {
    &::before {
      height: 56px;
      width: 46px;
      background-image: url(/assets/cs/decor/backslash-outline-2.svg);
    }
  }

  &.backslash-3 {
    &::before {
      height: 56px;
      width: 64px;
      background-image: url(/assets/cs/decor/backslash-outline-3.svg);
    }
  }

  .card-img, .card-img-top {
    width: 100%;
    border-radius: 15px 15px 0 0;
  }
}

.cs-card-plain, .news-card, .board-card {
  // This is to make sure that the "footers" of multiple cards line up nicely
  &::after {
    @extend .mb-5;
    display: block;
    content: " ";
    width: 100%;
    position: relative;
  }
  .card-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    .assign-button {
      margin-top: 15px;
    }
  }
}

.cs-card, .cs-card-plain, .icon-card, .news-card, .board-card {
  .card-footer {
    background-color: transparent !important;
  }
}

.featured-news-item {
  color: $cs-black;
}
